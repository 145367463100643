<template>
  <div
    v-if="isDataLoaded"
    class="px-12 py-4"
  >
    <confirm-accept-or-reject-team-dialog
      :mode="modeDialogConfirmOrRejectTeam"
      :visible="openAcceptOrRejectTeamDialog"
      @close-dialog-confirm-accept-or-reject-team="onCloseDialogConfirmAcceptOrRejectTeam"
      @confirm-accept-or-reject-team="onConfirmAcceptOrRejectTeam"
    />
    <v-row
      color="white"
      class="d-flex justify-space-between pl-12 pr-4 mt-12 w-100"
    >
      <v-col cols="8">
        <v-row class="d-flex flex-row justify-start align-center">
          <h2 class="mr-4" v-text="$t('common.inscription')" />
          <v-chip label outlined :color="teamApprovedStatus.color" v-text="teamApprovedStatus.label" />
        </v-row>
        <v-row class="d-flex justify-space-between mt-12 w-100">
          <v-col class="d-flex justify-start align-center pa-0">
            <div><v-icon color="primary" size="24" class="mr-3"> view_quilt</v-icon><span v-text="`${cohort.name}`" /></div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row class="d-flex flex-row justify-end align-center mb-6">
          <v-btn
            v-if="canBeDeleted"
            class="mr-4"
            outlined
            text
            @click="$confirm($t('team.confirmDelete')).then(confirm => confirm && deleteThisTeam())"
          >
            <v-icon left color="primary" v-text="'delete_outline'" />
            <span :style="{ color: $vuetify.theme.themes.light.primary }" v-text=" $t('Delete team')" />
          </v-btn>
          <v-btn color="primary" dark depressed @click="updateSuffix()" v-text="$t('common.save')" />
        </v-row>
        <v-row class="d-flex flex-row justify-end align-center">
          <span class="mr-4">
            {{ $t('team.approval.teamAccepted') }}
          </span>
          <v-switch
            v-model="isApproved"
            :disabled="!isUserManager"
            color="primary"
            class="ma-0"
            hide-details
            @change="openAcceptOrRejectTeamDialog = true"
          />
        </v-row>
      </v-col>
    </v-row>
    <div v-if="isUserManager || isApproved">
      <!-- <v-row class="d-flex justify-space-between mt-12" style="width: 100%;">
        <v-col
          cols="4"
          class="d-flex justify-end align-center"
        >
          <v-select
            v-if="adminApproved"
            v-model="numberOfPlayers"
            :items="allNumbers"
            :label="$t('titles.numberOf', $t('common.players'))"
            append-icon="mdi-chevron-down"
            style="max-width: 66%;"
            outlined
            dense
            hide-details
          />
        </v-col>
      </v-row> -->
      <!-- TODO: CLUB REVISAR FASE II -->
    </div>
    <v-row class="pl-12 pr-4 mt-12">
      <v-col class="pl-0" cols="12" sm="4">
        <v-text-field v-model="suffix" outlined dense :label="$t('team.fields.nameEdit')" />
      </v-col>
    </v-row>
    <div v-if="isUserManager || isApproved">
      <v-row class="pl-12 mt-12">
        <v-col cols="6" class="pa-0">
          <h2 class="mb-4" v-text="$t('common.attachments')" />
          <files-field
            v-model="team.attachments"
            @input="fileInput"
          />
        </v-col>
      </v-row>
      <v-row class="px-12 py-4">
        <v-tabs v-model="tab" color="secondary">
          <v-tabs-slider color="primary" />
          <v-tab v-for="{ id, name } in tabs" :key="id" class="text-capitalize" v-text="name" />
        </v-tabs>
        <v-divider style="border-right: 1px solid;" />
        <people-list
          v-if="cohortId"
          :list-type="tab ? 'staff' : 'players'"
          :organization-id="organizationId"
          :project-id="projectId"
          :cohort-id="cohortId"
          :team-id="teamId"
          :people-list="tab ? staff : players"
          :headers="tab ? staffHeaders : playersHeaders"
          style="width: 100%;"
        />
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Team',
  components: {
    PeopleList: () => import('@/modules/club/PeopleList'),
    FilesField: () => import('@/components/formFields/FilesField'),
    ConfirmAcceptOrRejectTeamDialog: () => import('@/modules/club/ConfirmAcceptOrRejectTeamDialog'),
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    clubId: { type: String, required: true },
    teamId: { type: String, required: true },
    cohortId: { type: String, required: true },
  },
  data() {
    return {
      players: [],
      attachments: [],
      tab: null,
      isApproved: false,
      numberOfPlayers: null,
      isDataLoaded: false,
      openAcceptOrRejectTeamDialog: false,
      suffix: null,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ club: 'club/data' }),
    ...mapGetters('competition', ['cohorts', 'matches', 'hasDisableTeamEditByClubFeature']),
    ...mapGetters({ teams: 'club/teams' }),
    ...mapGetters({ dbPlayers: 'club/players' }),
    ...mapGetters({ rawStaff: 'club/staff' }),
    ...mapGetters('project', ['isUserManager', 'isUserSubmanager']),
    team: ({ teams, teamId, cohortId }) => teams.find(team => team.id === teamId && team.cohortId === cohortId),
    cohort: ({ cohorts, cohortId }) => cohorts.find(cohort => cohort.id === cohortId),
    staff({ rawStaff }) {
      return rawStaff.sort(this.orderByCreatedAt)
    },
    subscriptionStatus({ club }) {
      const { paymentStatus } = club
      const colorDefault = this.$vuetify.theme.themes.light.error
      const colorByPaymentStatus = {
        notRequested: this.$vuetify.theme.themes.light.secondary,
        requested: this.$vuetify.theme.themes.light.warning,
        completed: this.$vuetify.theme.themes.light.success,
      }
      const labelDefault = this.$t('common.unkown')
      const label = this.$t(`team.status.${paymentStatus}`) || labelDefault
      const color = colorByPaymentStatus[paymentStatus] || colorDefault
      return { label, color }
    },
    canBeDeleted() {
      const teamHasMatches = !!this.matches.find(m => Object.keys(m.teamsById).includes(this.team.id) && m.cohortId === this.cohortId)
      return this.club.paymentStatus === 'notRequested' && !this.isApproved
        && !teamHasMatches && (!this.hasDisableTeamEditByClubFeature || this.isUserSubmanager)
    },
    teamApprovedStatus() {
      const label = (this.isApproved) ? this.$t('team.approval.accepted') : this.$t('common.pending')
      const color = (this.isApproved) ? this.$vuetify.theme.themes.light.success : this.$vuetify.theme.themes.light.secondary
      return { label, color }
    },
    modeDialogConfirmOrRejectTeam() {
      return this.isApproved ? 'accept' : 'reject'
    },
    isAdmin() {
      // this method determines if the current user is admin
      // currently not implemented in store.js
      return true
    },
    tabs() {
      return [
        { id: 'players', name: this.$t('common.players') },
        { id: 'staff', name: this.$t('common.staff') },
      ]
    },
    playersHeaders() {
      return [
        { text: this.$t('user.fields.avatar'), value: 'avatar' },
        { text: this.$t('user.fields.firstName'), value: 'firstName' },
        { text: this.$t('user.fields.lastName'), value: 'lastName' },
        { text: this.$t('user.fields.numberPlayer'), value: 'number' },
        { text: this.$t('user.fields.numberIdentification'), value: 'dni' },
        { text: this.$t('user.fields.birthdate'), value: 'birthdate' },
        { text: this.$t('user.fields.licenseCode'), value: 'licenseCode' },
        { text: this.$t('user.fields.parentEmail'), value: 'parentEmail' },
        { text: this.$t('user.fields.playerEmail'), value: 'email' },
        { text: this.$t('user.fields.linked'), value: 'userId' },
      ]
    },
    staffHeaders() {
      return [
        { text: this.$t('user.fields.avatar'), value: 'avatar' },
        { text: this.$t('user.fields.firstName'), value: 'firstName' },
        { text: this.$t('user.fields.lastName'), value: 'lastName' },
        { text: this.$t('user.fields.job'), value: 'job' },
        { text: this.$t('user.fields.numberIdentification'), value: 'dni' },
        { text: this.$t('user.fields.email'), value: 'email' },
        { text: this.$t('user.fields.phone'), value: 'phone' },
      ]
    },
    allNumbers() {
      return [...Array(25)].map((e, i) => (i + 1).toString())
    },
  },
  watch: {
    dbPlayers: {
      async handler(players) {
        this.$store.commit('loader/show')
        const parsedPlayers = await Promise.all(players.map(async player => {
          const { organizationId, projectId, cohortId } = this
          const playerId = player.id
          const privateData = await this.$store.dispatch('club/readPrivatePlayerData', { organizationId, projectId, cohortId, playerId }) ?? {}
          const { dni = '', birthdate, email = '', parentEmail = '' } = privateData
          return {
            ...player,
            ...(birthdate && ({ birthdate })),
            ...(dni && ({ dni })),
            ...(email && ({ email })),
            ...(parentEmail && ({ parentEmail })),
          }
        }))
        this.players = parsedPlayers
        this.$store.commit('loader/hide')
      },
    },
  },
  async created() {
    this.$store.commit('loader/show')
    const { organizationId, projectId, cohortId, teamId } = this
    await this.$store.dispatch('club/bindPlayers', { organizationId, projectId, cohortId, teamId })
    await this.$store.dispatch('club/bindStaff', { organizationId, projectId, cohortId, teamId })
    this.isApproved = this.team?.isApproved ?? false
    this.numberOfPlayers = this.dbPlayers.length
    this.suffix = this.team?.suffix ?? null
    this.$store.commit('loader/hide')
    this.isDataLoaded = true
  },

  methods: {
    orderByCreatedAt: (a, b) => a?.createdAt?.toDate() - b?.createdAt?.toDate(),
    // attachments
    retrieveAttachments(team) {
      this.attachments = team?.attachments
    },
    async updateSuffix() {
      const { organizationId, projectId, cohortId, teamId, suffix } = this
      this.runAsync(() => this.$store.dispatch('club/updateTeamSuffix', { organizationId, projectId, cohortId, teamId, suffix }))
    },
    async fileInput(data) {
      const { organizationId, projectId, cohortId, teamId } = this
      if (data) {
        this.runAsync(() => this.$store.dispatch('club/updateTeamAttachments', { organizationId, projectId, cohortId, teamId, data }))
      }
    },
    // TODO: test this method with real data
    async deleteThisTeam() {
      const { organizationId, projectId, cohortId, teamId } = this
      await this.runAsync(() => this.$store.dispatch('club/deleteTeam', { organizationId, projectId, cohortId, teamId }))
      this.$router.push({ name: 'club' })
    },
    onCloseDialogConfirmAcceptOrRejectTeam(mode) {
      this.openAcceptOrRejectTeamDialog = false
      this.isApproved = mode === 'reject'
    },
    onConfirmAcceptOrRejectTeam(mode) {
      this.openAcceptOrRejectTeamDialog = false
      const value = mode === 'accept'
      const { organizationId, projectId, cohortId, teamId } = this
      this.runAsync(() => this.$store.dispatch('club/modifyIsApproved', { organizationId, projectId, cohortId, teamId, value }))
    },
  },
}
</script>
